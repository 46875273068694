import { configureStore } from '@reduxjs/toolkit';

import { themeOptionSplice } from './themeOptions-splice';
import { ListSplice } from './list-splice';
import { AuthSplice } from './auth-splice';
import { InvoiceSplice } from './Invoice-splice';
import { LoadingSlice } from './loading-splice';
import { WalletSplice } from './wallet-splice';
import { DashboardSplice } from './dashboard-splice';
import { MakePaymentSplice } from './makePayment-splice';
import { ClientAccount } from './client-account-splice';
import { UsageSplice } from './usage-splice';
import { NotificationsSplice } from './notifications-splice';
import { SupportSplice } from './support-splice';

export const store = configureStore({
  reducer: {
    LoadingSlice: LoadingSlice.reducer,
    themeOptionSplice: themeOptionSplice.reducer,
    ListSplice: ListSplice.reducer,
    AuthSplice: AuthSplice.reducer,
    ClientAccount: ClientAccount.reducer,
    InvoiceSplice: InvoiceSplice.reducer,
    WalletSplice: WalletSplice.reducer,
    DashboardSplice: DashboardSplice.reducer,
    MakePaymentSplice: MakePaymentSplice.reducer,
    UsageSplice: UsageSplice.reducer,
    NotificationsSplice: NotificationsSplice.reducer,
    SupportSplice: SupportSplice.reducer
  }
});

export const {
  setSidebarShadow,
  setSidebarFixed,
  setSidebarStyle,
  setSidebarFooter,
  setSidebarToggleMobile,
  setSidebarToggle,
  setSidebarUserbox,
  setHeaderFixed,
  setHeaderShadow,
  setHeaderBgTransparent,
  setHeaderSearchHover,
  setHeaderDrawerToggle,
  setContentBackground,
  setThemeConfiguratorToggle,
  setFooterFixed,
  setFooterShadow,
  setFooterBgTransparent,
  setPageTitleStyle,
  setPageTitleBackground,
  setPageTitleShadow,
  setPageTitleIconBox,
  setPageTitleDescription
} = themeOptionSplice.actions;

export const {
  setLoading,
  setUser,
  setReseller,
  setToken,
  setKey,
  setLoginBackground
} = AuthSplice.actions;

export const { setClientAccount } = ClientAccount.actions;

export const {
  setDevicesList,
  setServicesList,
  setSimList,
  setSimViewList,
  setDeviceViewList,
  setServicePlanViewList,
  setSimActivationRequests,
  setMyFiles,
  setAllPrimaryContacts,
  setCreditNotes
} = ListSplice.actions;

export const {
  setStartedDate,
  setEndedDate,
  setSelectedStatus,
  setSearchInvoices,
  setInvoiceList,
  setInvoiceId,
  setInvoiceAutoPayWalletList
} = InvoiceSplice.actions;

export const { setIsLoading } = LoadingSlice.actions;

export const { setBillingHistory } = DashboardSplice.actions;

export const { setTransactionHistory } = MakePaymentSplice.actions;

export const { setUsageData } = UsageSplice.actions;

export const { setSupportData, setTicketFilter } = SupportSplice.actions;

export const {
  setUserNotifications,
  setUnreadNotifications,
  setNotificationsCount
} = NotificationsSplice.actions;
