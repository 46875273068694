import { createAsyncThunk } from '@reduxjs/toolkit';
// import { createNotification } from 'common/create-notification';
// import { setWalletList } from 'store/wallet-splice';
import axios from 'utils/axios';
import {
  GET_ADD_STRIPE_CARD_ROUTE,
  GET_STRIPE_CUSTOMER_PAYMENT_ACCOUNT_ROUTE,
  GET_WALLET_AUTOPAY_ROUTE,
  GET_WALLET_DELETE_ROUTE,
  GET_WALLET_ADD_PAYPAL_ROUTE,
  GET_WALLET_ADD_STRIPE_ACH_ROUTE,
  GET_WALLET_VERIFY_STRIPE_ACH_ROUTE
} from 'utils/endpoints';

export const handleAddStripeCard = async ({ data }) => {
  try {
    const res = await axios.post(`${GET_ADD_STRIPE_CARD_ROUTE}`, data, {
      xApi: true
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
export const handleAddStripeACH = async ({ data }) => {
  try {
    const res = await axios.post(`${GET_WALLET_ADD_STRIPE_ACH_ROUTE}`, data, {
      xApi: true
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
export const handleVerifyStripeAch = async ({ modalId, data }) => {
  try {
    const res = await axios.post(
      `${GET_WALLET_VERIFY_STRIPE_ACH_ROUTE}/${modalId}`,
      data,
      {
        xApi: true
      }
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
export const handleAddPayPal = async ({ data }) => {
  try {
    const res = await axios.post(`${GET_WALLET_ADD_PAYPAL_ROUTE}`, data, {
      xApi: true
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
export const handleWalletdelete = async ({ walletid }) => {
  try {
    const res = await axios.get(`${GET_WALLET_DELETE_ROUTE}/${walletid}`, {
      xApi: true
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
export const handleWalletAutoPay = async ({ walletid, autoPayValue }) => {
  try {
    const res = await axios.get(
      `${GET_WALLET_AUTOPAY_ROUTE}/${walletid}/${autoPayValue}`,
      {
        xApi: true
      }
    );

    if (res) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchResellerStripe = createAsyncThunk(
  'fetchResellerStripe',
  async ({ data }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${GET_STRIPE_CUSTOMER_PAYMENT_ACCOUNT_ROUTE}`,
        data,
        {
          xApi: true
        }
      );

      if (res.status === 200) {
        return res.data;
      } else {
        console.log('Failed to fetch Stripe Details');
      }
    } catch (error) {
      console.error('Error fetching Stripe Details:', error);
      throw error;
    }
  }
);
