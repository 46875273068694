import { createSlice } from '@reduxjs/toolkit';

import {
  fetchBillingHistory,
  fetchDashboardData
} from 'api-services/dashboard';

export const DashboardSplice = createSlice({
  name: 'DashboardSplice',
  initialState: {
    dashboardData: null,
    billingHistory: [],
    isLoading: false,
    error: null
  },
  reducers: {
    setBillingHistory(state, action) {
      state.billingHistory = action.payload;
    }
  },

  extraReducers: (builder) => {
    // dashboard Profile Section
    builder.addCase(fetchDashboardData.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload) {
        state.dashboardData = action.payload;
      }
    });
    builder.addCase(fetchDashboardData.rejected, (state, action) => {
      state.isLoading = false;
      console.error('Error fetching Dashboard Profile:');
    });

    // Billing history Cases
    builder.addCase(fetchBillingHistory.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchBillingHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (Array.isArray(action.payload)) {
        state.billingHistory = action.payload;
      }
    });
    builder.addCase(fetchBillingHistory.rejected, (state, action) => {
      state.isLoading = true;
      state.error = null;
      console.error('Error fetching Billing History');
    });
  }
});
