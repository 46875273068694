import React from 'react';
import clsx from 'clsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import useTabs from './helper';
import { useAppSelector } from 'store/hooks';

const HeaderMenu = () => {
  const history = useHistory();
  const { user } = useAppSelector((state) => state.AuthSplice);

  const { activeTab, toggle } = useTabs('1');

  return (
    <>
      <div className="app-header-menu app-header-menu d-flex justify-content-center">
        <Nav className="nav-line">
          <NavItem>
            <NavLink
              className={clsx({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
                history.push(`${process.env.REACT_APP_FOLDER_NAME}/dashboard`);
              }}>
              <span>Dashboard</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={clsx({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
                history.push(`${process.env.REACT_APP_FOLDER_NAME}/invoices`);
              }}>
              <span>Bill Pay</span>
            </NavLink>
          </NavItem>
          {user?.contact_permissions?.usages && (
            <NavItem>
              <NavLink
                className={clsx({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                  history.push(`${process.env.REACT_APP_FOLDER_NAME}/usage`);
                }}>
                <span>Usage</span>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={clsx({ active: activeTab === '4' })}
              onClick={() => {
                toggle('4');
                history.push(`${process.env.REACT_APP_FOLDER_NAME}/account`);
              }}>
              <span>Account</span>
            </NavLink>
          </NavItem>
          <a
            href={`https://www.apollosatellite.com/`}
            target="_blank"
            rel="noopener noreferrer"
            className={clsx('header-shop', { active: activeTab === '5' })}
            onClick={() => {
              toggle('5');
            }}>
            <span>Shop</span>
          </a>
        </Nav>
      </div>
    </>
  );
};

export default HeaderMenu;
