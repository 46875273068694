import React from 'react';
import clsx from 'clsx';

import HeaderDots from './HeaderDots/index';
// import HeaderSearch from './HeaderSearch/index';
import HeaderUserbox from './HeaderUserbox/index';
import HeaderMenu from './HeaderMenu/index';

import { store, setSidebarToggleMobile } from 'store';
import { useAppSelector } from 'store/hooks';
import HeaderText from './HeaderText';

const Header = () => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile
  } = useAppSelector((state) => state?.themeOptionSplice);

  const toggleSidebarMobile = () => {
    store.dispatch(
      setSidebarToggleMobile({ sidebarToggleMobile: !sidebarToggleMobile })
    );
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane menu">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <div className="p-0">
            <HeaderText />
          </div>
        </div>
        <div className="">
          <HeaderMenu />
        </div>
        <div className="d-flex justify-content-end">
          <div className="header-user-wrapper">
            {/* <HeaderSearch /> */}
            <HeaderDots />
            <HeaderUserbox />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
