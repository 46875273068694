import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { store, setUser, setToken, setKey } from 'store';
import { useAppSelector } from 'store/hooks';
import { LOGOUT_ROUTE } from 'utils/endpoints';
import { removeOlark } from 'utils/removeOlark';

const useLogout = () => {
  // const history = useHistory();
  const { user, token, key } = useAppSelector((state) => state?.AuthSplice);

  const [bool, setBool] = useState(false);
  const location = useLocation();

  const handleLogoutApi = () => {
    if (user) {
      var body = {
        authorization: token,
        api_key: key
      };
      var headers = { 'Content-Type': 'text/plain' };
      axios
        .create()
        .post(`${LOGOUT_ROUTE}/${user?.client?.userid}`, body, { headers })
        .then((response) => {
          if (response.status === 200) {
            setBool(true);
            store.dispatch(
              setUser({
                user: {}
              })
            );
            store.dispatch(
              setKey({
                key: ''
              })
            );
            store.dispatch(
              setToken({
                token: ''
              })
            );
            removeOlark();
            localStorage.clear();
            window.location.href = `${process.env.REACT_APP_FOLDER_NAME}/`;
          }
        });
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf('/logout') !== -1) {
      handleLogoutApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    handleLogoutApi,
    bool
  };
};

export default useLogout;
