import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientAccount: []
};

export const ClientAccount = createSlice({
  name: 'ClientAccount',
  initialState,
  reducers: {
    setClientAccount(state, { payload: { clientAccount } }) {
      state.clientAccount = clientAccount;
    }
  }
});
