import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationsCount: 0,
  notifications: [],
  unreadNotifications: [],
  isLoading: true,
  error: null
};

export const NotificationsSplice = createSlice({
  name: 'NotificationsSplice',
  initialState,
  reducers: {
    setNotificationsCount(state, { payload: { notificationsCount } }) {
      state.notificationsCount = notificationsCount;
    },
    setUserNotifications(state, { payload: { notifications } }) {
      state.notifications = notifications;
    },
    setUnreadNotifications(state, { payload: { unreadNotifications } }) {
      state.unreadNotifications = unreadNotifications;
      state.isLoading = false;
    }
  }
});

export const { setUserNotifications } = NotificationsSplice.actions;
export default NotificationsSplice.reducer;
