import { createSlice } from '@reduxjs/toolkit';

import { fetchTransactionHistory } from 'api-services/makeAPayment';

export const MakePaymentSplice = createSlice({
  name: 'MakePaymentSplice',
  initialState: {
    transactionHistory: [],
    paymentOptions: [],
    isLoading: false,
    error: null
  },
  reducers: {
    setTransactionHistory(state, action) {
      state.transactionHistory = action.payload;
    }
  },

  extraReducers: (builder) => {
    // Payment transaction Section
    builder.addCase(fetchTransactionHistory.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchTransactionHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload) {
        state.transactionHistory = action.payload.payment_history;
        state.paymentOptions = action.payload.payment_options;
      } else {
        console.error(
          'fetched Transactions History state conflicts with response in MakeAPayment-splice'
        );
      }
    });
  }
});
