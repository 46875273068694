import React, { forwardRef } from 'react';

import '../CommonInput/input.scss';
import { Input } from 'reactstrap';

const CommonInput = forwardRef(
  ({ labelValue, icon, iconClick, pointer, ...rest }, ref) => {
    return (
      <div className="input-icon-container">
        <div
          className={`input-icon ${pointer ? 'pointer' : ''}`}
          onClick={iconClick}>
          {icon}
        </div>
        <div className="input-container">
          <Input className="apollo-inputField" ref={ref} {...rest} />
          <label className={''}>{labelValue || ' Label'}</label>
        </div>
      </div>
    );
  }
);

export default CommonInput;
