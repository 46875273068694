import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, PopoverBody, UncontrolledPopover } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from 'react-loading-skeleton';

import { ReactComponent as BellIcon } from 'assets/icons/Dashboard/BellIcon.svg';
import NotificationsComponent from 'pages/Messages/NotificationsComponent';
import { useAppSelector } from 'store/hooks';
import { handleUnreadNotifications } from 'api-services/notifications';

const HeaderDots = () => {
  const { unreadNotifications, notificationsCount, isLoading } = useAppSelector(
    (state) => state.NotificationsSplice
  );
  // const { user } = useAppSelector((state) => state.AuthSplice);

  const [showNotification, setShowNotification] = useState(true);
  useEffect(() => {
    if (notificationsCount?.count > 0) {
      setShowNotification(true);
    }
  }, [notificationsCount]);

  const handleUnread = async () => {
    await handleUnreadNotifications({
      // clientid: user?.client?.userid,
      // contactid: user?.contact?.id
    });
  };

  return (
    <>
      <div className="header-bell-wrapper popover-header-wrapper position-relative">
        {notificationsCount?.count > 0 && showNotification ? (
          <Badge className="position-absolute rounded-circle badge-success">
            {notificationsCount.count}
          </Badge>
        ) : (
          ''
        )}
        <span
          className="d-inline-block"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowNotification(false);
            handleUnread();
          }}>
          <button
            id="alertsPopover"
            className=" p-0 border-0 bell-icon-wrapper">
            <span>
              <BellIcon />
            </span>
          </button>
          <UncontrolledPopover
            target="alertsPopover"
            trigger="legacy"
            className="popover-custom-wrapper popover-custom-lg"
            placement="bottom"
            offset={'[-40, 0]'}>
            <PopoverBody
              style={{
                minWidth: '405px',
                height: '350px',
                overflowY: 'auto',
                overscrollBehaviorBlock: 'contain'
              }}>
              <div className="overflow-hidden">
                <PerfectScrollbar>
                  <div className="px-3 pt-3">
                    {isLoading ? (
                      <>
                        <Skeleton height={95} />
                        <div className="mt-3">
                          <Skeleton height={95} />
                        </div>
                        <div className="mt-3">
                          <Skeleton height={95} />
                        </div>
                      </>
                    ) : (
                      unreadNotifications?.data?.map((item, index) => {
                        return (
                          <div key={index}>
                            {
                              <NotificationsComponent
                                color={'success'}
                                data={item}
                                alert
                              />
                            }
                          </div>
                        );
                      })
                    )}
                  </div>
                </PerfectScrollbar>
              </div>
              {unreadNotifications.status === false && (
                <div className="d-flex justify-content-center align-items-center light-fonts-14 h-75">
                  No Unread Notification is found!
                </div>
              )}
            </PopoverBody>
            <div className="w-100 text-center pb-3">
              <Link to={`${process.env.REACT_APP_FOLDER_NAME}/messages`}>
                <Badge color={'primary'} className="pointer">
                  View All Notifications
                </Badge>
              </Link>
            </div>
          </UncontrolledPopover>
        </span>
      </div>
    </>
  );
};

export default HeaderDots;
