import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true
};
export const LoadingSlice = createSlice({
  name: 'LoadingSlice',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.loading = action.payload.loading;
    }
  }
});
