import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  support: [],
  ticketStatus: ''
};

export const SupportSplice = createSlice({
  name: 'SupportSplice',
  initialState,
  reducers: {
    setSupportData(state, { payload: { support } }) {
      state.support = support;
    },
    setTicketFilter(state, action) {
      state.ticketStatus = action.payload;
    }
  }
});

const getTicketTable = (state) => state.SupportSplice.support.ticket_table;
const getTicketStatus = (state) => state.SupportSplice.ticketStatus;

export const getFilteredTickets = createSelector(
  [getTicketTable, getTicketStatus],
  (support, ticketStatus) => {
    // Create a copy to avoid mutating the original data
    let filteredItems = support ? [...support] : [];
    if (ticketStatus !== '') {
      filteredItems = filteredItems.filter((item) =>
        typeof ticketStatus === 'string'
          ? item.status.toLowerCase() === ticketStatus.toLowerCase()
          : filteredItems
      );
    } else {
      return filteredItems;
    }

    return filteredItems;
  }
);
