import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  devicesList: [],
  servicesList: [],
  simList: [],
  simViewList: {},
  deviceViewList: {},
  servicePlanViewList: {
    detail: {},
    simcard_detail: {},
    device_detail: {}
  },
  simActivationRequests: [],
  myFiles: [],
  primaryAllContacts: [],
  creditNotes: []
};

export const ListSplice = createSlice({
  name: 'ListSplice',
  initialState,
  reducers: {
    setDevicesList(state, { payload: { devicesList } }) {
      state.devicesList = devicesList;
    },
    setServicesList(state, { payload: { servicesList } }) {
      if (Array.isArray(servicesList)) {
        state.servicesList = servicesList;
      } else if (!Array.isArray(servicesList)) {
        return (state.servicesList = []);
      }
    },
    setSimList(state, { payload: { simList } }) {
      if (Array.isArray(simList)) {
        state.simList = simList;
      } else if (!Array.isArray(simList)) {
        return (state.simList = []);
      }
    },
    setSimViewList(state, { payload: { simViewList } }) {
      state.simViewList = simViewList;
    },
    setDeviceViewList(state, { payload: { deviceViewList } }) {
      state.deviceViewList = deviceViewList;
    },
    setServicePlanViewList(state, { payload: { servicePlanViewList } }) {
      state.servicePlanViewList = servicePlanViewList;
    },
    setSimActivationRequests(state, { payload: { simActivationRequests } }) {
      if (Array.isArray(simActivationRequests)) {
        state.simActivationRequests = simActivationRequests;
      } else if (!Array.isArray(simActivationRequests)) {
        state.simActivationRequests = [];
      }
    },
    setMyFiles(state, { payload: { myFiles } }) {
      state.myFiles = myFiles;
    },
    setAllPrimaryContacts(state, { payload: { primaryAllContacts } }) {
      state.primaryAllContacts = primaryAllContacts;
    },
    setCreditNotes(state, { payload: { creditNotes } }) {
      state.creditNotes = creditNotes;
    }
  }
});
