import axios from 'axios';

import { createNotification } from 'common/create-notification';
import { store } from 'store';

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
} else {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
}

axios.interceptors.request.use(
  (req) => {
    const state = store.getState();
    req.headers['Content-Type'] = 'text/plain';

    if (state?.AuthSplice?.token) {
      req.headers.Authorization = state?.AuthSplice?.token;
    }
    if (req.xApi) {
      req.headers['X-API-KEY'] = state?.AuthSplice?.key;
    }
    // console.log('..................', req);
    return req;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (['post', 'put', 'delete'].includes(res?.config?.method.toLowerCase())) {
      if (res?.data?.status === false) {
        createNotification('error', res?.message);
      }
    }
    return res;
  },
  (error) => {
    // if (error?.message === 'Network Error') {
    //   createNotification('error', 'Something went wrong please check network');
    // }
    createNotification('error', error?.response?.data?.message);
    if (
      error?.response?.statusText === 'Unauthorized' &&
      error?.response?.status === 401
    ) {
      localStorage.removeItem('token');
      return 'Unauthorized';
    }
    return error;
  }
);

export default axios;
