import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

import './clientList.scss';
import { useAppSelector } from 'store/hooks';
import { handleClientLogIn } from 'api-services/clientAccount';
import { setLoading, store } from 'store';

const ClientList = (props) => {
  const { user } = useAppSelector((state) => state.AuthSplice);
  const { dashboardData } = useAppSelector((state) => state.DashboardSplice);

  const clientList = dashboardData?.attached_customers || [];
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handleSelectedClient = async (id) => {
    store.dispatch(setLoading({ loading: true }));
    const newFormData = new FormData();
    newFormData.append('client_id', id);
    await handleClientLogIn({
      data: newFormData,
      userid: id,
      setIsLoading
    });
    store.dispatch(setLoading({ loading: false }));
    props.toggledropdown();
  };
  return (
    <>
      <div className="pl-3 pointer d-flex" onClick={toggle}>
        <div className="nav-link-icon pr-2">
          <FontAwesomeIcon icon="users" />
        </div>
        <span>{`LoggedIn As (${user?.client?.company})`}</span>
        <span className="ml-auto mr-4">
          <FontAwesomeIcon icon="caret-down" />
        </span>
      </div>
      <Collapse isOpen={isOpen}>
        <ul className="pl-3 mt-2">
          <PerfectScrollbar>
            {clientList?.map(
              (item, index) =>
                item?.client_name && (
                  <li
                    key={index}
                    className={clsx(`client mt-1 d-flex mx-4`, {
                      active: item.active_client === '1'
                    })}
                    onClick={() => handleSelectedClient(item.clientid)}>
                    <span>{item.client_name}</span>
                  </li>
                )
            )}
          </PerfectScrollbar>
        </ul>
      </Collapse>
    </>
  );
};

export default ClientList;
