import React from 'react';
import { Link } from 'react-router-dom';

const ButtonBlue = ({ children, to, classes, style, onClick, ...rest }) => {
  return (
    <>
      {to ? (
        <Link to={to}>
          <button
            className={`${
              (classes && classes) || ''
            } blue-button border-0 text-white p-2 px-3 font-weight-bold rounded-pill `}
            style={{
              ...style
            }}
            onClick={onClick}
            {...rest}>
            {children}
          </button>
        </Link>
      ) : (
        <button
          className={`${
            (classes && classes) || ''
          } blue-button border-0 text-white p-2 px-3 font-weight-bold rounded-pill `}
          style={{
            ...style
          }}
          onClick={onClick}
          {...rest}>
          {children}
        </button>
      )}
    </>
  );
};

export default ButtonBlue;
