import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import {
  GET_ACTIVATE_SIMCARD_ROUTE,
  GET_DASHBOARD_PAYMENT_ROUTE,
  GET_DASHBOARD_PROFILE_ROUTE,
  GET_SIMCARD_DEVICE_BY_ID_ROUTE
} from 'utils/endpoints';

export const fetchDashboardData = createAsyncThunk(
  'fetchDashboardData',
  async ({ dispatch }) => {
    try {
      const res = await axios.get(`${GET_DASHBOARD_PROFILE_ROUTE}`, {
        xApi: true
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      throw error;
    }
  }
);

export const fetchBillingHistory = createAsyncThunk(
  'fetchBillingHistory',
  async ({ dispatch }) => {
    try {
      const res = await axios.get(`${GET_DASHBOARD_PAYMENT_ROUTE}`, {
        xApi: true
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.error('Error fetching Billing History data:', error);
      throw error;
    }
  }
);

export const handleSimCardActivation = async ({ data, simid }) => {
  try {
    const res = await axios.post(
      `${GET_ACTIVATE_SIMCARD_ROUTE}/${simid}`,
      data,
      {
        xApi: true
      }
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.error(
      'An error occurred while Posting SIM Activation data:',
      error
    );
    throw error;
  }
};

export const handleGetDeviceBySimId = async ({ simid, clientid }) => {
  try {
    const res = await axios.get(
      `${GET_SIMCARD_DEVICE_BY_ID_ROUTE}/${simid}/${clientid}`,
      {
        xApi: true
      }
    );

    if (res.status === 200) {
      let resObj = res?.data;
      return resObj;
    }
  } catch (error) {
    console.error(
      'An error occurred while fetching SIM Related Device:',
      error
    );
    throw error;
  }
};
