import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Form } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ButtonBlue from 'components/button/buttonBlue';
import CommonInput from 'components/input/CommonInput';
import { createNotification } from 'common/create-notification';
import { handleResetPassword } from 'api-services/auth';
import SmallLoader from 'common/smallLoader';

const ReapeatPasswordComponent = () => {
  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(repeatPasswordSchema)
  });
  const { errors } = formState;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const getUrlSegment = (segmentIndex) => {
    const segments = window.location.pathname.split('/');
    return segments[segmentIndex];
  };

  const staff = getUrlSegment('3');
  const userid = getUrlSegment('4');
  const passKey = getUrlSegment('5');

  const onSubmit = async (formData) => {
    const newFormData = new FormData();
    for (const key in formData) {
      newFormData.append(key, formData[key]);
    }
    newFormData.append('staff', staff);
    newFormData.append('userid', userid);
    newFormData.append('new_pass_key', passKey);
    if (staff && userid && passKey) {
      setLoading(true);
      const res = await handleResetPassword({
        data: newFormData
      });
      if (res?.status === false) {
        setLoading(false);

        history.push(`${process.env.REACT_APP_FOLDER_NAME}/`);
      }
      if (res?.status === true) {
        setLoading(false);
        createNotification('success', res.message);
        history.push(`${process.env.REACT_APP_FOLDER_NAME}/`);
      } else {
        setLoading(false);
        history.push(`${process.env.REACT_APP_FOLDER_NAME}/`);
      }
    } else {
      setLoading(false);
      createNotification(
        'error',
        'Something went wrong while fetching user data'
      );
      history.push(`${process.env.REACT_APP_FOLDER_NAME}/`);
    }
  };

  return (
    <>
      <div className="min-vh-100 bg-dark">
        <Row className="d-flex justify-content-center align-items-center min-vh-100">
          <Col className="col-6">
            <Card className="card-bg">
              <div className="text-center card-main-heading pt-4 pr-4">
                Reset Your Password
              </div>
              {loading ? (
                <>
                  <SmallLoader />
                </>
              ) : (
                <CardBody className="">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-3">
                      <Col>
                        <div className="">
                          <div className="pt-1">
                            <Controller
                              control={control}
                              name="password"
                              defaultValue={''}
                              render={({ field }) => (
                                <CommonInput
                                  type="text"
                                  labelValue={'Password'}
                                  invalid={errors?.password && true}
                                  {...field}
                                />
                              )}
                            />
                            {errors?.password?.message !== undefined && (
                              <div className="text-danger mt-1 text-12">
                                {`*${errors?.password?.message}`}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="my-4">
                          <div className="pt-1">
                            <Controller
                              control={control}
                              name="repeatPassword"
                              defaultValue={''}
                              render={({ field }) => (
                                <CommonInput
                                  type="text"
                                  labelValue={'Repeat Password'}
                                  invalid={errors?.repeatPassword && true}
                                  {...field}
                                />
                              )}
                            />
                            {errors?.repeatPassword?.message !== undefined && (
                              <div className="text-danger mt-1 text-12">
                                {`*${errors?.repeatPassword?.message}`}
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="pt-4 text-center">
                          <ButtonBlue classes={'px-5'} type="submit">
                            Reset
                          </ButtonBlue>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReapeatPasswordComponent;

const repeatPasswordSchema = yup.object().shape({
  password: yup.string().required('password is required'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('password is required')
});
