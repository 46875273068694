import axios from 'utils/axios';
import {
  GET_NOTIFICATIONS_COUNT_ROUTE,
  GET_NOTIFICATIONS_DELETE_ROUTE,
  GET_UNREAD_NOTIFICATIONS_ROUTE,
  GET_USER_NOTIFICATIONS_ROUTE
} from 'utils/endpoints';
import { store } from 'store';
import { setUserNotifications } from 'store';
import { setUnreadNotifications } from 'store';
import { setNotificationsCount } from 'store';

export const handleNotifications = async ({ status }) => {
  try {
    const res = await axios.get(`${GET_USER_NOTIFICATIONS_ROUTE}/${status}`, {
      xApi: true
    });

    if (res.status === 200) {
      let resObj = res?.data;

      store.dispatch(
        setUserNotifications({
          notifications: resObj
        })
      );
    }

    return res?.data;
  } catch (error) {
    console.error(
      'An error occurred while fetching User Notifications:',
      error
    );
    throw error;
  }
};

export const handleUnreadNotifications = async () => {
  try {
    const res = await axios.get(`${GET_UNREAD_NOTIFICATIONS_ROUTE}`, {
      xApi: true
    });

    if (res.status === 200) {
      store.dispatch(setUnreadNotifications({ unreadNotifications: res.data }));
    }

    return res?.data;
  } catch (error) {
    console.error(
      'An error occurred while fetching unread notifications :',
      error
    );
    throw error;
  }
};

export const handleNotificationsCount = async () => {
  try {
    const res = await axios.get(`${GET_NOTIFICATIONS_COUNT_ROUTE}`, {
      xApi: true
    });
    if (res.status === 200) {
      store.dispatch(
        setNotificationsCount({
          notificationsCount: res.data
        })
      );
    }

    return res?.data;
  } catch (error) {
    console.error(
      'An error occurred while fetching notifications count :',
      error
    );
    throw error;
  }
};

export const handleNotificationsDelete = async ({ id }) => {
  try {
    const res = await axios.get(`${GET_NOTIFICATIONS_DELETE_ROUTE}/${id}`, {
      xApi: true
    });
    if (res.status === 200) {
      return res?.data;
    }
  } catch (error) {
    console.error(
      'An error occurred while fetching notifications count :',
      error
    );
    throw error;
  }
};
