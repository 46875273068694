import React from 'react';

import { DotLoader } from 'react-spinners';
import { Row } from 'reactstrap';

const SmallLoader = () => {
  return (
    <Row
      className="align-items-center justify-content-center w-100"
      style={{ width: '150px', height: '80px' }}>
      <DotLoader color={'#203754'} />
    </Row>
  );
};

export default SmallLoader;
