import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import useLogout from './helper';
import ButtonBlue from 'components/button/buttonBlue';
import ClientList from 'components/Nav/ClientList';

const HeaderUserbox = () => {
  const { handleLogoutApi, bool } = useLogout();
  const { user } = useAppSelector((state) => state?.AuthSplice);
  const profileImage = user?.contact?.profile_image || '';
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      className="d-flex"
      style={{
        background: '#eee',
        padding: '8px',
        borderRadius: '60px',
        height: '78px'
      }}
      isOpen={dropdownOpen}
      toggle={toggle}>
      <DropdownToggle
        color="link"
        className="p-0 d-flex btn-transition-none align-items-center"
        style={{ width: '100px' }}>
        <div className="avatar-icon-xs">
          <img
            width={58}
            height={58}
            className="rounded-circle"
            src={profileImage}
            alt={user?.contact?.firstname || 'profile_img'}
          />
        </div>
        <span className="mx-auto pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-xl p-0">
        <div className="bg-composed-wrapper bg-vicious-stance mt-0">
          <div className="bg-composed-wrapper--image bg-composed-img-5" />
          <div className="bg-composed-wrapper--content text-white text-center p-4">
            <h5 className="mb-1">
              {`${user?.contact?.firstname} ${user?.contact?.lastname}` || ''}
            </h5>
            <p className="mb-0 opacity-7">{user?.contact?.email || ''}</p>
          </div>
        </div>

        <NavLinkStrap
          className="d-flex"
          tag={NavLink}
          exact
          to={`${process.env.REACT_APP_FOLDER_NAME}/profile`}
          onClick={toggle}>
          <div className="nav-link-icon pr-2">
            <FontAwesomeIcon icon="user" />
          </div>
          <span>Profile</span>
        </NavLinkStrap>
        <div className="dropdown-divider"></div>

        <ClientList toggledropdown={toggle} />

        <div className="card-footer d-flex justify-content-between">
          <ButtonBlue
            classes={'w-100'}
            onClick={handleLogoutApi}
            disabled={bool}>
            Logout
          </ButtonBlue>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default HeaderUserbox;
