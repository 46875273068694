import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usageData: [],
  isLoading: false,
  error: null
};

export const UsageSplice = createSlice({
  name: 'UsageSplice',
  initialState,
  reducers: {
    setUsageData(state, { payload: { usageData } }) {
      state.usageData = usageData;
    }
  }
});

export const { setUsageData } = UsageSplice.actions;
export default UsageSplice.reducer;
