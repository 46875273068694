import React from 'react';
import clsx from 'clsx';

import { useAppSelector } from 'store/hooks';

const Footer = () => {
  const { footerShadow, footerBgTransparent } = useAppSelector(
    (state) => state?.themeOptionSplice
  );

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={clsx('app-footer text-black-50', {
        'app-footer--shadow': footerShadow,
        'app-footer--opacity-bg': footerBgTransparent
      })}>
      <div className="app-footer--second">
        <span>{`© ${currentYear} SatPhonePortal.com ⎯ "Space-Age Service, Down-to-Earth Dependability".`}</span>
      </div>
    </div>
  );
};

export default Footer;
