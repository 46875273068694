import React, { useEffect, useState } from 'react';
import { chatBotAxiosInstance } from 'utils/axios_chatbot';
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  toggleMsgLoader
} from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

import './newchatbotai.css';
import { useAppSelector } from 'store/hooks';
import { GET_AI_CHAT_ROUTE } from 'utils/endpoints';

const NewChatbot = () => {
  const { user, token, key } = useAppSelector((state) => state.AuthSplice);

  const [chatHistory, setChatHistory] = useState(() => {
    const savedHistory = localStorage.getItem('chathistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });

  useEffect(() => {
    localStorage.setItem('chathistory', JSON.stringify(chatHistory));
  }, [chatHistory]);

  useEffect(() => {
    const history = JSON.parse(localStorage.getItem('chathistory'));
    if (history && history.length > 0) {
      history.forEach((obj) => {
        if (obj.sender) {
          addUserMessage(obj.sender);
        }
        if (obj.response) {
          addResponseMessage(obj.response);
        }
      });
    }
  }, []);

  const handleNewUserMessage = async (newMessage) => {
    setChatHistory((prevHistory) => [...prevHistory, { sender: newMessage }]);

    toggleMsgLoader();
    const response = await chatBotAxiosInstance.post(GET_AI_CHAT_ROUTE, {
      key: key,
      token: token,
      thread_id: user?.contact?.id,
      message: newMessage
    });
    if (response?.data?.response) {
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { response: response?.data?.response }
      ]);
      toggleMsgLoader();
      addResponseMessage(response?.data?.response);
    } else {
      toggleMsgLoader();
      addResponseMessage('Sorry! Something went wrong');
    }
  };

  return (
    <Widget
      emojis={false}
      showBadge={false}
      showTimeStamp={false}
      handleNewUserMessage={handleNewUserMessage}
      profileClientAvatar={user?.contact?.profile_image}
      title="SatPhone Ai"
      subtitle="Your Personal Ai Assistant"
    />
  );
};

export default NewChatbot;
