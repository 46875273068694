import React from 'react';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import clsx from 'clsx';

import { useAppSelector } from 'store/hooks';
import { store, setSidebarToggle, setSidebarToggleMobile } from 'store';
import SidebarUserbox from '../SidebarUserbox';

const SidebarHeader = () => {
  const { sidebarToggle, sidebarToggleMobile } = useAppSelector(
    (state) => state?.themeOptionSplice
  );

  const toggleSidebarMobile = () => {
    store.dispatch(
      setSidebarToggleMobile({ sidebarToggleMobile: !sidebarToggleMobile })
    );
  };
  const toggleSidebar = () => {
    store.dispatch(setSidebarToggle({ sidebarToggle: !sidebarToggle }));
  };

  return (
    <div className="app-sidebar--header">
      <div
        className={clsx('w-100', {
          'pl-4': !sidebarToggle,
          'pl-1': sidebarToggle
        })}>
        <SidebarUserbox />
      </div>
      <button
        onClick={toggleSidebar}
        className="btn btn-sm collapse-sidebar-btn"
        id="CollapseSidebarTooltip">
        <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
      </button>
      <UncontrolledTooltip
        target="CollapseSidebarTooltip"
        container=".app-sidebar"
        placement="right">
        Collapse sidebar
      </UncontrolledTooltip>
      <button
        className={clsx(
          'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
          { 'is-active': sidebarToggleMobile }
        )}
        onClick={toggleSidebarMobile}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <button
        onClick={toggleSidebar}
        className="expand-sidebar-btn btn btn-sm"
        id="ExpandSidebarTooltip">
        <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
      </button>
      <UncontrolledTooltip
        target="ExpandSidebarTooltip"
        container=".app-sidebar"
        placement="right">
        Expand sidebar
      </UncontrolledTooltip>
    </div>
  );
};

export default SidebarHeader;
