import axios from 'utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { GET_WALLET_ROUTE, GET_AMOUNT_HISTORY_ROUTE } from 'utils/endpoints';
import { fetchResellerStripe } from 'api-services/wallet';

const initialState = {
  modalId: '',
  publishableKey: '',
  amountHistory: [],
  walletCardsList: [],
  isLoading: false,
  error: null
};

export const WalletSplice = createSlice({
  name: 'WalletSplice',
  initialState,
  reducers: {
    setWalletList(state, { payload: { walletCardsList } }) {
      state.walletCardsList = walletCardsList;
    },
    setModalId(state, { payload: { modalId } }) {
      state.modalId = modalId;
    }
  },
  extraReducers: (builder) => {
    // Wallet Cards List Cases
    builder.addCase(fetchWalletCards.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchWalletCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (Array.isArray(action.payload)) {
        state.walletCardsList = action.payload;
      } else if (!Array.isArray(action.payload)) {
        state.walletCardsList = [];
        console.error('fetched wallet cards is not an array in wallet-splice');
      }
    });

    // Amount History Cases
    builder.addCase(fetchAmountHistory.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAmountHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.amountHistory = action.payload;
    });

    // Stripe Cases
    builder.addCase(fetchResellerStripe.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchResellerStripe.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.publishableKey = action.payload;
    });
  }
});

export const fetchWalletCards = createAsyncThunk(
  'fetchWalletCards',
  async ({ dispatch }) => {
    try {
      const res = await axios.get(`${GET_WALLET_ROUTE}`, {
        xApi: true
      });

      if (res.status === 200) {
        const walletCardsList = res.data;
        return walletCardsList;
      } else {
        console.log('Failed to fetch wallet data');
      }
    } catch (error) {
      console.error('Error fetching wallet data:', error);
      throw error;
    }
  }
);

export const fetchAmountHistory = createAsyncThunk(
  'fetchAmountHistory',
  async ({ dispatch }) => {
    try {
      const res = await axios.get(`${GET_AMOUNT_HISTORY_ROUTE}`, {
        xApi: true
      });

      if (res.status === 200) {
        return res.data;
      } else {
        console.log('Failed to fetch Amount History data');
      }
    } catch (error) {
      console.error('Error fetching Amount History data:', error);
      throw error;
    }
  }
);

export const { setWalletList, setModalId } = WalletSplice.actions;
export default WalletSplice.reducer;
