const startUrl = 'api/customer_portal_api';

//endpoints for Auth
export const LOGIN_ROUTE = `${startUrl}/login/auth`;
export const LOGOUT_ROUTE = `${startUrl}/login/logout_contact`;
export const GET_LOGIN_DATA_ROUTE = `${startUrl}/contacts`;
export const GET_ALL_COTACTS_ROUTE = `${startUrl}/contacts/all_contacts`;
export const GET_RESELLER_DATA_ROUTE = `${startUrl}/resellers`;

// endpoints for Client Account
export const GET_CLIENT_ACCOUNTS_ROUTE = `${startUrl}/accounts`;
export const GET_CLIENT_FEEDBACK_ROUTE = `${startUrl}/accounts/account_feedback`;

//endpoints for Profile Editing
export const UPDATE_PASSWORD_ROUTE = `${startUrl}/contacts/update_password`;
export const UPDATE_PROFILE_CONTACT_ROUTE = `${startUrl}/contacts/contact_data`;
export const UPDATE_PROFILE_CLIENT_ROUTE = `${startUrl}/clients/client_data`;
export const REMOVE_PROFILE_IMAGE_ROUTE = `${startUrl}/contacts/remove_profile_image`;
export const UPDATE_CLIENT_META_DATA_ROUTE = `${startUrl}/clients/meta_data`;
export const ADD_CLIENT_PHONENUMBER_ROUTE = `${startUrl}/clients/client_phone`;
export const REMOVE_CLIENT_PHONENUMBER_ROUTE = `${startUrl}/clients/remove_client_phone`;
export const UPDATE_EMAIL_NOTIFICATIONS_ROUTE = `${startUrl}/contacts/update_email_notifications`;

// endpoints for invoices
export const GET_INVOICES_ROUTE = `${startUrl}/invoices`;
export const GET_INVOICES_AUTOPAY_ROUTE = `${startUrl}/invoices/set_auto_payment`;
export const GET_SINGLE_INVOICE_ROUTE = `${startUrl}/invoice_receipts/invoice/`;
export const GET_INVOICE_DOWNLOAD_ROUTE = `${startUrl}/invoice_receipts/invoice_download/`;
export const GET_INVOICE_PAYNOW_ROUTE = `${startUrl}/invoice_receipts/invoice_paynow/`;
export const GET_INVOICE_AUTOPAY_ROUTE = `${startUrl}/wallet/invoice_auto_pay_list`;

// endpoints for devices
export const GET_DEVICES_ROUTE = `${startUrl}/devices`;
// endpoints for services
export const GET_SERVICES_ROUTE = `${startUrl}/services`;
export const GET_SERVICES_CODE_NAME_ROUTE = `${startUrl}/services/update_service_plan_code`;

// endpoints for simcards
export const GET_SIM_ROUTE = `${startUrl}/sim_cards`;
export const GET_SIM_ACTIVATION_STATUS_ROUTE = `${startUrl}/sim_cards/simcard_activation`;

// endpoints for Wallet
export const GET_WALLET_ROUTE = `${startUrl}/wallet`;
export const GET_WALLET_DELETE_ROUTE = `${startUrl}/wallet/delete_wallet`;
export const GET_AMOUNT_HISTORY_ROUTE = `${startUrl}/wallet/amount_history`;
export const GET_ADD_STRIPE_CARD_ROUTE = `${startUrl}/wallet/add_stripe_card`;
export const GET_STRIPE_CUSTOMER_PAYMENT_ACCOUNT_ROUTE = `${startUrl}/wallet/stripe_customer_payment_account`;
export const GET_WALLET_AUTOPAY_ROUTE = `${startUrl}/wallet/update_auto_pay`;
export const GET_WALLET_ADD_PAYPAL_ROUTE = `${startUrl}/wallet/add_paypal_card`;
export const GET_WALLET_ADD_STRIPE_ACH_ROUTE = `${startUrl}/wallet/add_stripe_ach`;
export const GET_WALLET_VERIFY_STRIPE_ACH_ROUTE = `${startUrl}/wallet/verify_stripe_ach_account`;

// endpoints for Dashboard
export const GET_DASHBOARD_PROFILE_ROUTE = `${startUrl}/dashboard`;
export const GET_DASHBOARD_PAYMENT_ROUTE = `${startUrl}/payments`;
export const GET_ACTIVATE_SIMCARD_ROUTE = `${startUrl}/dashboard/activate_simcard`;
export const GET_SIMCARD_DEVICE_BY_ID_ROUTE = `${startUrl}/dashboard/get_imei_by_simid`;
export const GET_LOGGEDIN_CLIENT_ROUTE = `${startUrl}/dashboard/update_active_client`;

// endpoints for Payments
export const GET_TRANSACTION_HISTORY_ROUTE = `${startUrl}/payments`;

// endpoints for Login
export const GET_LOGIN_BACKGROUND_ROUTE = `${startUrl}/client_portal/background`;
export const GET_LOGIN_FORGOT_PASSWORD_ROUTE = `${startUrl}/login/forgot_password`;
export const GET_LOGIN_RESET_PASSWORD_ROUTE = `${startUrl}/login/reset_password`;

// endpoints for Contact
export const GET_CONTACT_US_ROUTE = `${startUrl}/contacts/contact_us`;

// endpoints for Usage
export const GET_USAGE_ROUTE = `${startUrl}/usage/usage_serviceplan_simcard`;

// endpoints for Notifications
export const GET_USER_NOTIFICATIONS_ROUTE = `${startUrl}/dashboard/user_notifications`;
export const GET_UNREAD_NOTIFICATIONS_ROUTE = `${startUrl}/dashboard/client_notifications_unread`;
export const GET_NOTIFICATIONS_COUNT_ROUTE = `${startUrl}/dashboard/unread_notifications_count`;
export const GET_NOTIFICATIONS_DELETE_ROUTE = `${startUrl}/dashboard/client_notifications_dismiss`;

// endpoints for Support
export const GET_SUPPORT_ROUTE = `${startUrl}/support`;
export const GET_SUPPORT_TICKET_ROUTE = `${startUrl}/support/ticket_create`;

// endpoints for Files
export const GET_FILES_ROUTE = `${startUrl}/files`;

// endpoints for CreditNotes
export const GET_CREDITNOTES_ROUTE = `${startUrl}/credit_notes`;

// endpoints for AI-Chat
export const GET_AI_CHAT_ROUTE = `chat`;
