import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { ChevronRight } from 'react-feather';

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  const simCardsTabActive = window.location.pathname.indexOf('SimCards') !== -1;

  return (
    <>
      {item.show && (
        <NavLink
          to={`${item.path}`}
          onClick={item.subNav && showSubnav}
          activeClassName={
            item.subNav && clsx({ active: subnav || simCardsTabActive })
          }
          className={'d-flex justify-content-center'}>
          <div>
            {item.icon && (
              <span className="sidebar-icon" style={{ fontSize: '1.3rem' }}>
                {item.icon}
              </span>
            )}
          </div>
          <span className="sidebar-item-label" style={{ fontSize: '14px' }}>
            {item.title}
          </span>
          <span className="sidebar-icon-indicator">
            {item.subNav && subnav ? (
              <ChevronRight />
            ) : item.subNav ? (
              <ChevronRight />
            ) : null}
          </span>
        </NavLink>
      )}
      {item.subNav && subnav && (
        <ul className="p-0">
          {item.subNav.map((subitem, index) => (
            <li key={index} className="">
              <NavLink to={subitem.path}>
                <span
                  style={{
                    fontSize: '13px',
                    paddingLeft: '5px'
                  }}
                  className="">
                  {subitem.title}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
export default SubMenu;
