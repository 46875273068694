import { useEffect } from 'react';

function RedirectHTTPToHTTPS() {
  useEffect(() => {
    // Check if the base URL is not "localhost:3000"
    if (
      window.location.protocol === 'http:' &&
      !window.location.host.includes('localhost:3000')
    ) {
      window.location.href =
        'https://' + window.location.host + window.location.pathname;
    }
  }, []);

  return null;
}

export default RedirectHTTPToHTTPS;
