//*Custom Hook for Tabs Active Reference to their Routes*//

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useTabs = (initialTab) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    let currentPath = location.pathname;

    if (
      currentPath.includes(`${process.env.REACT_APP_FOLDER_NAME}/dashboard`)
    ) {
      setActiveTab('1');
    } else if (
      currentPath.includes(`${process.env.REACT_APP_FOLDER_NAME}/invoices`)
    ) {
      setActiveTab('2');
    } else if (
      currentPath.includes(`${process.env.REACT_APP_FOLDER_NAME}/usage`)
    ) {
      setActiveTab('3');
    } else if (
      currentPath.includes(`${process.env.REACT_APP_FOLDER_NAME}/account`)
    ) {
      setActiveTab('4');
    } else setActiveTab('0');
  }, [location.pathname]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return { activeTab, toggle };
};

export default useTabs;
