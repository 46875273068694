import React from 'react';

import { ReactComponent as Newsicon } from 'assets/icons/Dashboard/Newsicon.svg';
import { ReactComponent as Messageicon } from 'assets/icons/Dashboard/Messageicon.svg';
import { ReactComponent as Supporticon } from 'assets/icons/Dashboard/Supporticon.svg';
import { ReactComponent as Paymenticon } from 'assets/icons/Dashboard/Paymenticon.svg';
import { ReactComponent as Walleticon } from 'assets/icons/Dashboard/Walleticon.svg';
// import { ReactComponent as Overviewicon } from 'assets/icons/Dashboard/Overviewicon.svg';
import { ReactComponent as Devicesicon } from 'assets/icons/Dashboard/Devicesicon2.svg';
import { ReactComponent as Servicesicon } from 'assets/icons/Dashboard/Servicesicon.svg';
import { ReactComponent as Billsicon } from 'assets/icons/Dashboard/Billsicon.svg';
// import { ReactComponent as Preferencesicon } from 'assets/icons/Dashboard/Preferenceicon.svg';
import { ReactComponent as Contacticon } from 'assets/icons/Dashboard/Contacticon.svg';
import { ReactComponent as MyContacts } from 'assets/icons/Dashboard/MyContacts.svg';
import { ReactComponent as Simsicon } from 'assets/icons/Dashboard/sim-card-sidebar.svg';
import { ReactComponent as Filesicon } from 'assets/icons/FilesDownload/Folder.svg';

import { useAppSelector } from 'store/hooks';

export const useSidebar = () => {
  const { reseller, user } = useAppSelector((state) => state?.AuthSplice);

  const resellerName = reseller?.reseller_contact;
  const permissions = user?.contact_permissions;

  const SidebarData = [
    {
      show: true,
      title: 'Dashboard',
      path: `${process.env.REACT_APP_FOLDER_NAME}/dashboard`,
      icon: <Newsicon />
    },
    // {
    //   title: 'News & Updates',
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/newsupdates`,
    //   icon: <Newsicon />
    // },
    {
      show: permissions?.services ? true : false,
      title: 'My Services',
      path: `${process.env.REACT_APP_FOLDER_NAME}/services`,
      icon: <Servicesicon />
      // subNav: [
      //   {
      //     title: 'Active',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/services/active`
      //   },
      //   {
      //     title: 'Suspended',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/services/suspended`
      //   },
      //   {
      //     title: 'Inactive',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/services/inactive`
      //   },
      //   {
      //     title: 'Usage',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/services/usage`
      //   },
      //   {
      //     title: 'Call Logs',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/services/call_logs`
      //   }
      // ]
    },
    {
      show: permissions?.simcards ? true : false,
      title: 'My Sim Cards',
      path: `${process.env.REACT_APP_FOLDER_NAME}/simcards`,
      icon: <Simsicon />
      // subNav: [
      //   {
      //     title: 'Active',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/active`
      //   },
      //   {
      //     title: 'Suspended',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/suspended`
      //   },
      //   {
      //     title: 'Inactive',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/inactive`
      //   }
      //   // {
      //   //   title: 'Usage',
      //   //   path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/usage`
      //   // }
      // ]
    },
    {
      show: permissions?.devices ? true : false,
      title: 'My Devices',
      path: `${process.env.REACT_APP_FOLDER_NAME}/my-devices`,
      icon: <Devicesicon />
      // subNav: [
      //   {
      //     title: 'Active',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/devices/active`
      //   },
      //   {
      //     title: 'Suspended',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/devices/suspended`
      //   },
      //   {
      //     title: 'Inactive',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/devices/inactive`
      //   }
      //   // {
      //   //   title: 'Usage',
      //   //   path: `${process.env.REACT_APP_FOLDER_NAME}/devices/usage`
      //   // }
      // ]
    },
    {
      show: permissions?.makepayments ? true : false,
      title: 'Make A Payment',
      path: `${process.env.REACT_APP_FOLDER_NAME}/payments`,
      icon: <Paymenticon />
    },
    {
      show: permissions?.invoices ? true : false,
      title: 'View Bills',
      path: `${process.env.REACT_APP_FOLDER_NAME}/invoices`,
      icon: <Billsicon />
      // subNav: [
      //   {
      //     title: 'Invoices',
      //     path: `${process.env.REACT_APP_FOLDER_NAME}/invoices`
      //   }
      // ]
    },
    {
      show: permissions?.wallets ? true : false,
      title: 'My Wallet',
      path: `${process.env.REACT_APP_FOLDER_NAME}/mywallet`,
      icon: <Walleticon />
    },
    {
      show: true,
      title: 'Messages & Notifications',
      path: `${process.env.REACT_APP_FOLDER_NAME}/messages`,
      icon: <Messageicon />
    },
    // {
    //   title: 'Support Request',
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/supportrequest`,
    //   icon: <Supporticon />
    // },
    // {
    //   title: 'Order History',
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/orderhistory`,
    //   icon: <Supporticon />
    // },

    // {
    //   title: 'Account Overview',
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/overview`,
    //   icon: <Overviewicon />
    // },
    {
      show: user?.contact?.is_primary === '1' ? true : false,
      title: 'My Contacts',
      path: `${process.env.REACT_APP_FOLDER_NAME}/mycontacts`,
      icon: <MyContacts />
    },
    {
      show: permissions?.files ? true : false,
      title: 'My Files',
      path: `${process.env.REACT_APP_FOLDER_NAME}/files`,
      icon: <Filesicon />
    },

    // {
    //   title: 'Perefrences',
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/preferences`,
    //   icon: <Preferencesicon />,
    //   subNav: [
    //     // {
    //     //   title: 'Invoices',
    //     //   path: `${process.env.REACT_APP_FOLDER_NAME}` + '/invoices',
    //     // }
    //   ]
    // },
    {
      show: permissions?.support ? true : false,
      title: 'Support Center',
      path: `${process.env.REACT_APP_FOLDER_NAME}/supportcenter`,
      icon: <Supporticon />
    },
    {
      show: true,
      title: `Contact ( ${resellerName?.firstname || ''} ${
        resellerName?.lastname || ''
      } )`,
      path: `${process.env.REACT_APP_FOLDER_NAME}/contact`,
      icon: <Contacticon />
    }
  ];

  return {
    SidebarData
  };
};
