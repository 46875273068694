import React from 'react';

import { ScaleLoader } from 'react-spinners';

const FullscreenLoaderWhite = () => {
  return (
    <>
      <div className="modal-fullscreen-loading-white d-flex justify-content-center align-items-center">
        <div>
          <ScaleLoader color={'#203754'} width={50} />
          <div className="text-black mt-3 text-center"> Please Wait...</div>
        </div>
      </div>
    </>
  );
};

export default FullscreenLoaderWhite;
