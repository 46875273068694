import axios from 'axios';
import { createNotification } from 'common/create-notification';

export const chatBotAxiosInstance = axios.create({
  baseURL: process?.env?.REACT_APP_CHAT_URL
});

chatBotAxiosInstance.interceptors.request.use(
  (req) => {
    req.headers['Content-Type'] = 'application/json';

    return req;
  },

  (error) => {
    return Promise.reject(error);
  }
);

chatBotAxiosInstance.interceptors.response.use(
  (res) => {
    if (['post', 'put', 'delete'].includes(res?.config?.method.toLowerCase())) {
      if (res?.data?.status === false) {
        createNotification('error', res?.message);
      }
    }
    return res;
  },
  (error) => {
    // if (error?.message === 'Network Error') {
    //   createNotification('error', 'Something went wrong please check network');
    // }
    createNotification('error', error?.response?.data?.message);
    if (
      error?.response?.statusText === 'Unauthorized' &&
      error?.response?.status === 401
    ) {
      localStorage.removeItem('token');
      return 'Unauthorized';
    } else {
    }

    return error?.response?.data?.message;
  }
);
