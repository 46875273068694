import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Sidebar from 'components/Nav/Sidebar';
import Header from 'components/Nav/Header';
import Footer from 'components/Nav/Footer';

import { useAppSelector } from 'store/hooks';

const AppLayout = ({ children }) => {
  const {
    sidebarFixed,
    sidebarToggle,
    headerDrawerToggle,
    sidebarToggleMobile,
    contentBackground,
    headerSearchHover,
    headerFixed,
    footerFixed
  } = useAppSelector((state) => state?.themeOptionSplice);

  return (
    <div
      className={clsx('app-wrapper', contentBackground, {
        'header-drawer-open': headerDrawerToggle,
        'app-sidebar-collapsed': sidebarToggle,
        'app-sidebar-mobile-open': sidebarToggleMobile,
        'app-sidebar-fixed': sidebarFixed,
        'app-header-fixed': headerFixed,
        'app-footer-fixed': footerFixed,
        'search-wrapper-open': headerSearchHover
      })}>
      <div>
        <Sidebar />
      </div>
      <div className="app-main">
        <Header />
        <div className="app-content">
          <div className="app-content--inner mt-2 p-0">
            <div className="app-content--inner__wrapper">{children}</div>
          </div>
          <div className="pt-4">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node
};

export default AppLayout;
