import React from 'react';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Btnlogo } from 'assets/svgs/sidebar-mydetails.svg';
import { ReactComponent as Collapsedlogo } from 'assets/icons/Dashboard/SidebarCollapsed.svg';
import { useAppSelector } from 'store/hooks';

const SidebarUserbox = () => {
  const { sidebarToggle } = useAppSelector((state) => state?.themeOptionSplice);
  const { reseller } = useAppSelector((state) => state?.AuthSplice);

  const reseller_image = reseller?.reseller?.profile_image;

  return (
    <div className="app-sidebar--userbox mb-3">
      <div className="sidebar-img--bg d-flex justify-content-center align-items-center mb-2">
        <NavLink to={`${process.env.REACT_APP_FOLDER_NAME}/dashboard`}>
          <img
            className={'img-fluid'}
            alt="..."
            src={reseller_image && reseller_image}
          />
        </NavLink>
      </div>

      <Button
        style={{ background: 'white' }}
        size="sm rounded-pill"
        tag={NavLink}
        to={`${process.env.REACT_APP_FOLDER_NAME}/profile`}
        className="sidebar-detail--btn mt-2">
        {sidebarToggle ? (
          <Collapsedlogo
            className="mx-1
          "
          />
        ) : (
          <Btnlogo
            className="mx-1
        "
          />
        )}
        <span className="sidebar-details--font"> My Details</span>
      </Button>
    </div>
  );
};

export default SidebarUserbox;
