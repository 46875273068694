import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, UncontrolledAlert } from 'reactstrap';

import { setUserNotifications } from 'store';
import { store } from 'store';
// import { useAppSelector } from 'store/hooks';
import { handleNotificationsDelete } from 'api-services/notifications';
import moment from 'moment';

const NotificationsComponent = ({
  alert,
  uncontrolledAlert,
  circle,
  color,
  data
}) => {
  // const { user } = useAppSelector((state) => state.AuthSplice);

  const onDelete = async (id) => {
    const res = await handleNotificationsDelete({
      id
    });

    store.dispatch(
      setUserNotifications({
        notifications: res
      })
    );
  };

  return (
    <>
      {alert && (
        <Alert
          color={color}
          className="d-flex align-items-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          {circle && (
            <span
              className={`bg-${color} rounded-circle mt-1 ml-1 mr-3`}
              style={{
                height: '15px',
                width: '15px',
                minWidth: '15px'
              }}></span>
          )}
          <span>
            {data?.description}
            {data.link && (
              <>
                <Link
                  to={`${process.env.REACT_APP_FOLDER_NAME}/${data.link}`}
                  className="alert-link mx-1">
                  Click here
                </Link>
                to view.
              </>
            )}
          </span>
        </Alert>
      )}

      {uncontrolledAlert && (
        <UncontrolledAlert
          color={color}
          className="d-flex align-items-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          {circle && (
            <span
              className={`bg-${color} rounded-circle mt-1 ml-1 mr-3`}
              style={{
                height: '15px',
                width: '15px',
                minWidth: '15px'
              }}></span>
          )}
          <span>
            <span className="font-weight-bold">
              {`${
                (!!data.created_at && moment(data.created_at).format('ll')) ||
                ''
              } - `}
            </span>
            {data?.description}
            {data.link && (
              <>
                <Link
                  to={`${process.env.REACT_APP_FOLDER_NAME}/${data.link}`}
                  className="alert-link mx-1">
                  Click here
                </Link>
                to view.
              </>
            )}
            <Button
              close
              onClick={(e) => {
                e.preventDefault();
                onDelete(data.id);
              }}
            />
          </span>
        </UncontrolledAlert>
      )}
    </>
  );
};

export default NotificationsComponent;
