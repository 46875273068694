import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router';
// import { Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import AppLayout from 'components/AppLayout';

import { useRoutes } from './helper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getLoginUser } from 'api-services/auth';
import FullscreenLoaderWhite from 'common/fullscreenLoaderWhite';
import { fetchDashboardData } from 'api-services/dashboard';
// import NotFound from 'pages/404';

const Routing = () => {
  const { extraRoutes, publicRoutes, privateRoutes } = useRoutes();
  const { user, token, loading } = useAppSelector((state) => state?.AuthSplice);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.2
  };

  useEffect(() => {
    if (token !== '') {
      getLoginUser({ setIsLoading });
    }
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(fetchDashboardData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {extraRoutes.map(({ path, element }, index) => (
              <Route path={path} key={index}>
                {element}
              </Route>
            ))}
            {!token &&
              publicRoutes.map(({ path, element }, index) => (
                <Route path={path} key={index}>
                  {element}
                </Route>
              ))}
            {isLoading || loading ? (
              <SuspenseLoading />
            ) : (
              token && (
                // privateRoutes.some(
                //   ({ path }) => path === window.location.pathname
                // )
                <AppLayout>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    {token &&
                      //  (
                      //   <Redirect
                      //     from={`${process.env.REACT_APP_FOLDER_NAME}/`}
                      //     to={`${process.env.REACT_APP_FOLDER_NAME}/dashboard`}
                      //   />
                      // )}
                      privateRoutes.map(
                        ({ show, path, element }, index) =>
                          show && (
                            <Route exact path={path} key={index}>
                              {element}
                            </Route>
                          )
                      )}
                  </motion.div>
                </AppLayout>
                // ) : (
                //   <Route>
                //     <NotFound />
                //   </Route>
              )
            )}
          </Switch>
        </Suspense>
      </AnimatePresence>
    </>
  );
};

export default Routing;

const SuspenseLoading = () => <FullscreenLoaderWhite />;
