import React from 'react';
import clsx from 'clsx';

import SidebarHeader from './SidebarHeader/index';
import SidebarMenu from './SidebarMenu/index';

import { store, setSidebarToggleMobile } from 'store';
import { useAppSelector } from 'store/hooks';

const Sidebar = () => {
  const { sidebarStyle, sidebarShadow, sidebarToggleMobile } = useAppSelector(
    (state) => state?.themeOptionSplice
  );

  const toggleSidebarMobile = () => {
    store.dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };

  return (
    <>
      <div
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow
        })}>
        <div className="">
          <SidebarHeader />
        </div>
        <div className="app-sidebar--content">
          <SidebarMenu sidebarUserbox={true} />
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile
        })}
      />
    </>
  );
};

export default Sidebar;
