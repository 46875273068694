import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Sidebar
  sidebarFixed: true,
  sidebarFooter: true,
  sidebarShadow: false,
  sidebarStyle: 'app-sidebar--dark',
  sidebarUserbox: true,
  sidebarToggleMobile: false,
  sidebarToggle: false,

  // Header
  headerFixed: true,
  headerShadow: true,
  headerBgTransparent: true,
  headerSearchHover: false,
  headerDrawerToggle: false,

  // Main content
  contentBackground: '',
  themeConfiguratorToggle: false,

  // Footer
  footerFixed: false,
  footerShadow: false,
  footerBgTransparent: true,

  // Page title
  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleIconBox: true,
  pageTitleDescription: true
};

export const themeOptionSplice = createSlice({
  name: 'ThemeOption',
  initialState,
  reducers: {
    // Sidebar
    setSidebarShadow(state, { payload: { sidebarShadow } }) {
      state.sidebarShadow = sidebarShadow;
    },
    setSidebarFixed(state, { payload: { sidebarFixed } }) {
      state.sidebarFixed = sidebarFixed;
    },
    setSidebarStyle(state, { payload: { sidebarStyle } }) {
      state.sidebarStyle = sidebarStyle;
    },
    setSidebarFooter(state, { payload: { sidebarFooter } }) {
      state.sidebarFooter = sidebarFooter;
    },
    setSidebarToggleMobile(state, { payload: { sidebarToggleMobile } }) {
      state.sidebarToggleMobile = sidebarToggleMobile;
    },
    setSidebarToggle(state, { payload: { sidebarToggle } }) {
      state.sidebarToggle = sidebarToggle;
    },
    setSidebarUserbox(state, { payload: { sidebarUserbox } }) {
      state.sidebarUserbox = sidebarUserbox;
    },

    // Header
    setHeaderFixed(state, { payload: { headerFixed } }) {
      state.headerFixed = headerFixed;
    },
    setHeaderShadow(state, { payload: { headerShadow } }) {
      state.headerShadow = headerShadow;
    },
    setHeaderBgTransparent(state, { payload: { headerBgTransparent } }) {
      state.headerBgTransparent = headerBgTransparent;
    },
    setHeaderSearchHover(state, { payload: { headerSearchHover } }) {
      state.headerSearchHover = headerSearchHover;
    },
    setHeaderDrawerToggle(state, { payload: { headerDrawerToggle } }) {
      state.headerDrawerToggle = headerDrawerToggle;
    },

    // Main content
    setContentBackground(state, { payload: { contentBackground } }) {
      state.contentBackground = contentBackground;
    },
    setThemeConfiguratorToggle(
      state,
      { payload: { themeConfiguratorToggle } }
    ) {
      state.themeConfiguratorToggle = themeConfiguratorToggle;
    },

    // Footer
    setFooterFixed(state, { payload: { footerFixed } }) {
      state.footerFixed = footerFixed;
    },
    setFooterShadow(state, { payload: { footerShadow } }) {
      state.footerShadow = footerShadow;
    },
    setFooterBgTransparent(state, { payload: { footerBgTransparent } }) {
      state.footerBgTransparent = footerBgTransparent;
    },

    // Page title
    setPageTitleStyle(state, { payload: { pageTitleStyle } }) {
      state.pageTitleStyle = pageTitleStyle;
    },
    setPageTitleBackground(state, { payload: { pageTitleBackground } }) {
      state.pageTitleBackground = pageTitleBackground;
    },
    setPageTitleShadow(state, { payload: { pageTitleShadow } }) {
      state.pageTitleShadow = pageTitleShadow;
    },
    setPageTitleIconBox(state, { payload: { pageTitleIconBox } }) {
      state.pageTitleIconBox = pageTitleIconBox;
    },
    setPageTitleDescription(state, { payload: { pageTitleDescription } }) {
      state.pageTitleDescription = pageTitleDescription;
    }
  }
});
