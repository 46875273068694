import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  key: localStorage.getItem('key') || '',
  token: localStorage.getItem('token') || '',
  user: {},
  reseller: {},
  loading: false,
  error: null,
  loginBackgroundImg: ''
};

export const AuthSplice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken(state, { payload: { token } }) {
      localStorage.setItem('token', token);
      state.token = token;
    },
    setKey(state, { payload: { key } }) {
      localStorage.setItem('key', key);
      state.key = key;
    },
    setUser(state, { payload: { user } }) {
      state.user = user;
    },
    setReseller(state, { payload: { reseller } }) {
      state.reseller = reseller;
    },
    setLoginBackground(state, { payload: { loginBackgroundImg } }) {
      state.loginBackgroundImg = loginBackgroundImg;
    },
    setLoading(state, { payload: { loading } }) {
      state.loading = loading;
    }
  }
});
