import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

// import SidebarUserbox from '../SidebarUserbox';
import SubMenu from '../SidebarMenu/SubMenu';
import { useSidebar } from './SidebarData';

const SidebarMenu = () => {
  const { SidebarData } = useSidebar();

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <ul>
            <li>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default SidebarMenu;
