export const removeOlark = () => {
  const scripts = document.body.getElementsByTagName('script');
  const scriptsToRemove = [];

  // Loop through each script element
  for (let i = 0; i < scripts.length; i++) {
    const script = scripts[i];

    // Check if the script type attribute is "text/javascript"
    if (script.getAttribute('type') === 'text/javascript') {
      scriptsToRemove.push(script);
    }
  }
  // Remove all script elements with type="text/javascript" from the body
  scriptsToRemove.forEach((script) => {
    script.parentNode.removeChild(script);
  });

  //   const removeChatWidget = document.getElementById('hbl-live-chat-wrapper');
  //   if (removeChatWidget && removeChatWidget.parentNode) {
  //     // Check if the element and its parent node exist before attempting to remove
  //     removeChatWidget.parentNode.removeChild(removeChatWidget);
  //   }
};
