import React, { lazy } from 'react';
import ReapeatPasswordComponent from 'pages/auth/Login/RepeatPassword';
import { useAppSelector } from 'store/hooks';

const NotFound = lazy(() => import('pages/404'));
const Login = lazy(() => import('pages/auth/Login'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Wallet = lazy(() => import('pages/Wallet'));
const ViewMyBill = lazy(() => import('pages/ViewMyBill'));
const SimCards = lazy(() => import('pages/SimCards'));
const SimInfo = lazy(() => import('pages/SimCards/SimCardInfo'));
// const ActiveSimCards = lazy(() => import('pages/SimCards/activeSimCards'));
// const InactiveSimCards = lazy(() => import('pages/SimCards/InactiveSimCards'));
// const SuspendedSimCards = lazy(() =>
//   import('pages/SimCards/suspendedSimCards')
// );
const Invoices = lazy(() => import('pages/Invoices'));
const CreditNotesView = lazy(() => import('pages/views/CreditNotes'));
const MyFiles = lazy(() => import('pages/FilesDownload/index.js'));
const Devices = lazy(() => import('pages/Devices'));
const DevicesInfo = lazy(() => import('pages/Devices/DevicesInfo'));
// const ActiveDevices = lazy(() => import('pages/Devices/active-devices'));
// const SuspendedDevices = lazy(() => import('pages/Devices/suspended-devices'));
// const InactiveDevices = lazy(() => import('pages/Devices/inactive-devices'));
const Profile = lazy(() => import('pages/Profile'));
const Services = lazy(() => import('pages/Services'));
const ServicesPlanInfo = lazy(() => import('pages/Services/ServicePlanInfo'));
const NexsusInvoice = lazy(() => import('pages/views/NexsusInvoice'));
const MakePayment = lazy(() => import('pages/MakePayment'));
const SupportCenter = lazy(() => import('pages/SupportCenter'));
const MyTickets = lazy(() => import('pages/SupportCenter/MyTickets'));
const Messages = lazy(() => import('pages/Messages'));
const ContactPage = lazy(() => import('pages/Contact'));
const MyContactsPage = lazy(() => import('pages/MyContacts'));
const Usage = lazy(() => import('pages/Usage'));
const Account = lazy(() => import('pages/Account'));
const SetClient = lazy(() => import('pages/auth/SetClient'));

export const useRoutes = () => {
  const { user } = useAppSelector((state) => state.AuthSplice);

  const permissions = user?.contact_permissions;
  const extraRoutes = [
    {
      path: `${process.env.REACT_APP_FOLDER_NAME}/invoice`,

      element: <NexsusInvoice />
    },
    {
      path: `${process.env.REACT_APP_FOLDER_NAME}/creditnote`,

      element: <CreditNotesView />
    },
    {
      path: `${process.env.REACT_APP_FOLDER_NAME}/resetpassword`,

      element: <ReapeatPasswordComponent />
    },
    {
      path: `${process.env.REACT_APP_FOLDER_NAME}/setclient`,
      element: <SetClient />
    }
  ];

  const publicRoutes = [
    {
      path: `${process.env.REACT_APP_FOLDER_NAME}/`,
      element: <Login />
    }
  ];

  const privateRoutes = [
    {
      show: true,
      path: `${process.env.REACT_APP_FOLDER_NAME}/`,
      element: <Dashboard />
    },
    {
      show: true,
      path: `${process.env.REACT_APP_FOLDER_NAME}/dashboard`,
      element: <Dashboard />
    },
    {
      show: permissions?.makepayments ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/payments`,
      element: <MakePayment />
    },
    {
      show: permissions?.makepayments === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/payments`,
      element: <NotFound />
    },
    {
      show: permissions?.wallets ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/mywallet`,
      element: <Wallet />
    },
    {
      show: permissions?.wallets === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/mywallet`,
      element: <NotFound />
    },
    {
      show: true,
      path: `${process.env.REACT_APP_FOLDER_NAME}/mybill`,
      element: <ViewMyBill />
    },
    {
      show: permissions?.simcards ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/simcards`,
      element: <SimCards />
    },
    {
      show: permissions?.simcards === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/simcards`,
      element: <NotFound />
    },
    {
      show: permissions?.simcards ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/siminfo/:id`,
      element: <SimInfo />
    },
    {
      show: permissions?.simcards === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/siminfo/:id`,
      element: <NotFound />
    },
    // {
    //   show: true,
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/active`,
    //   element: <ActiveSimCards />
    // },
    // {
    //   show: true,
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/suspended`,
    //   element: <SuspendedSimCards />
    // },
    // {
    //   show: true,
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/simcards/inactive`,
    //   element: <InactiveSimCards />
    // },
    {
      show: permissions?.invoices ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/invoices`,
      element: <Invoices />
    },
    {
      show: permissions?.invoices === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/invoices`,
      element: <NotFound />
    },
    {
      show: permissions?.files ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/files`,
      element: <MyFiles />
    },
    {
      show: permissions?.files === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/files`,
      element: <NotFound />
    },
    {
      show: user?.contact?.is_primary === '1' ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/mycontacts`,
      element: <MyContactsPage />
    },
    {
      show: user?.contact?.is_primary === '0',
      path: `${process.env.REACT_APP_FOLDER_NAME}/mycontacts`,
      element: <NotFound />
    },
    {
      show: permissions?.devices ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/my-devices`,
      element: <Devices />
    },
    {
      show: permissions?.devices === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/my-devices`,
      element: <NotFound />
    },
    {
      show: permissions?.devices ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/my-devices/devicesinfo/:id`,
      element: <DevicesInfo />
    },
    {
      show: permissions?.devices === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/my-devices/devicesinfo/:id`,
      element: <NotFound />
    },
    // {
    //   show: true,
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/devices/active`,
    //   element: <ActiveDevices />
    // },
    // {
    //   show: true,
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/devices/suspended`,
    //   element: <SuspendedDevices />
    // },
    // {
    //   show: true,
    //   path: `${process.env.REACT_APP_FOLDER_NAME}/devices/inactive`,
    //   element: <InactiveDevices />
    // },
    {
      show: true,
      path: `${process.env.REACT_APP_FOLDER_NAME}/profile`,
      element: <Profile />
    },
    {
      show: permissions?.services ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/services`,
      element: <Services />
    },
    {
      show: permissions?.services === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/services`,
      element: <NotFound />
    },
    {
      show: permissions?.services ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/services/serviceplaninfo/:id`,
      element: <ServicesPlanInfo />
    },
    {
      show: permissions?.services === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/services/serviceplaninfo/:id`,
      element: <NotFound />
    },
    {
      show: permissions?.support ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/supportcenter`,
      element: <SupportCenter />
    },
    {
      show: permissions?.support === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/supportcenter`,
      element: <NotFound />
    },
    {
      show: permissions?.support ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/supportcenter/mytickets`,
      element: <MyTickets />
    },
    {
      show: permissions?.support === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/supportcenter/mytickets`,
      element: <NotFound />
    },
    {
      show: true,
      path: `${process.env.REACT_APP_FOLDER_NAME}/messages`,
      element: <Messages />
    },
    {
      show: permissions?.usages ? true : false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/usage`,
      element: <Usage />
    },
    {
      show: permissions?.usages === false,
      path: `${process.env.REACT_APP_FOLDER_NAME}/usage`,
      element: <NotFound />
    },
    {
      show: true,
      path: `${process.env.REACT_APP_FOLDER_NAME}/account`,
      element: <Account />
    },
    {
      show: true,
      path: `${process.env.REACT_APP_FOLDER_NAME}/contact`,
      element: <ContactPage />
    }

    //All Routes should be at top of the NOT FOUND Route
    // {
    //   path: `*`,
    //   element: <NotFound />
    // }
  ];

  return {
    extraRoutes,
    publicRoutes,
    privateRoutes
  };
};
