import React from 'react';
import { useAppSelector } from 'store/hooks';

const HeaderText = () => {
  const { user } = useAppSelector((state) => state?.AuthSplice);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 17) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  const greeting = getGreeting();

  return (
    <div className="font-weight-bold text-black text-20 header-text">
      {`${greeting} ${user?.contact?.firstname} ${user?.contact?.lastname}`}
      <div className="apollo-font header-text mt-1">
        {user?.client?.company}
      </div>
    </div>
  );
};

export default HeaderText;
